<template>
  <div>
    <div v-if="partners && partners.length">
      <v-row
        v-for="(partner, index) in partners"
        :key="index"
        class="d-flex flex-row"
      >
        <v-hover v-slot="{ hover }">
          <v-card
            :class="`px-4
              py-4
              rounded
              primary--text
              d-flex
              align-content-space-between
              justify-space-between
              align-center
              ${hover ? 'elevation-1' : 'elevation-0'}`"
            style="
              transition: all 0.2s ease-in-out;
              width: 100%;
              margin-top: 10px;
            "
            outlined
          >
            <a
              class="text-decoration-none"
              @click="handlerPartner(partner)"
              v-if="partner"
            >
              <div class="d-flex flex-column">
                <span>
                  {{ partner.name }} -
                  {{ partner.relationship }}
                </span>
                <span v-if="partner" class="deep-orange--text text-sm-body-2">
                  {{ getSubtitle(partner) }}
                </span>
              </div>
            </a>

            <v-icon
              @click="handlerRemovePartner(partner)"
              size="20"
              class="red--text text--darken-4"
              v-if="!viewPartner"
            >
              mdi-delete
            </v-icon>
          </v-card>
        </v-hover>
      </v-row>
    </div>
    <div
      v-else
      class="text-center blue-grey--text text--lighten-4 font-weight-light"
    >
      <p>No Partner Linked</p>
    </div>
    <v-spacer />

    <!-- v-if="
        !this.viewPartner &&
        (!this.partners || this.maxPartners <= this.partners.length)
      " -->
    <v-btn
      v-if="showInvitePartner"
      class="mt-8"
      color="primary"
      rounded
      block
      large
      to="/my-partner/invitationType"
    >
      Connect a Partner
    </v-btn>
  </div>
</template>

<script>
import { auth, api, partner } from '../../sharedPlugin';

export default {
  data() {
    return {
      partners: null,
      loading: false,
      viewPartner: false,
      maxPartners: 1,
      showInvitePartner: false,
      isPulling: false,
      countDown: 60
    };
  },
  created() {
    this.maxPartners = this.user?.clinicSettings?.max_partners || 1;
    this.load();
    this.viewPartner = this.getPartner && this.getPartner.viewPartner;
    if (this.$store.state.isPulling) {
      this.pullingPartner();
      this.$store.commit('setPulling', false);
    }
  },
  computed: {
    ...auth.mapComputed(['user']),
    ...partner.mapComputed(['getPartner'])
  },
  methods: {
    ...auth.mapMethods(['switchPatients']),
    ...partner.mapMethods(['setLocalPartner']),
    titleCase(text) {
      let _text = text;
      try {
        _text
          .toLowerCase()
          .split(' ')
          .map((word) => {
            if (word) {
              return word && word.length > 0
                ? word[0].toUpperCase() + word.slice(1)
                : '';
            }
          })
          .join(' ');
      } catch (error) {
        console.log('ERROR:', error);
      }
      return _text;
    },
    pullingPartner() {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1;
          this.load();
          this.pullingPartner();
        }, 5000);
      }
    },
    getSubtitle(partner) {
      let _subtitle = '';

      if (partner.consent === 0) {
        _subtitle = `Awaiting partner response`;
        if (partner.isPartner === 1) {
          _subtitle = `Pending invitation acceptance`;
        }
      } else if (partner.consent === 1) {
        _subtitle = `Tap here to access your partner's information`;

        if (this.viewPartner) {
          _subtitle = `Tap here to leave your partner's account`;
        }
      }

      return _subtitle;
    },
    getDate(date) {
      date = date.split('T')[0];
      date = date.split('-');
      var month = date[1];
      var day = date[2];
      var year = date[0];

      return `${year}-${month}-${day}`;
    },
    async load() {
      this.loading = true;

      await api.PartnerConnect.getPartners()
        .then((result) => {
          if (result && result.length > 0) {
            result.map((item) => {
              item.name = this.titleCase(item.name);
              let dateOfBirth = new Date(item.dateOfBirth);
              item.dateOfBirth = this.getDate(dateOfBirth.toISOString());
              return item;
            });

            result.forEach((element) => {
              if (element.consent === 0 && element.isPartner === 1) {
                this.setLocalPartner(element);
              }
            });

            this.viewPartner = this.getPartner && this.getPartner.viewPartner;
            let patientEmail = this.getPartner && this.getPartner.patientEmail;

            if (this.viewPartner && patientEmail) {
              const isPatient = (res) => res.email === patientEmail;
              let filterResult = result.filter(isPatient);
              this.partners = filterResult;
              if (filterResult && filterResult.length > 0) {
                this.$store.commit('setOwnerName', filterResult[0].name);
              }
            } else {
              this.partners = result;
            }
          }
          this.partners = result;
        })
        .finally(() => {
          this.loading = false;
          if (
            !this.viewPartner &&
            (!this.partners || this.maxPartners >= this.partners.length)
          ) {
            this.showInvitePartner = true;
          } else {
            this.showInvitePartner = false;
          }
        });
    },
    handlerRemovePartner(partner) {
      this.setLocalPartner(partner);
      this.$router.push(`/my-partner/unlink/${partner.id}`);
    },
    handlerPartner(partner) {
      this.viewPartner = this.getPartner && this.getPartner.viewPartner;
      partner.ownerName = this.user.fullName;

      if (partner.isPartner == 1 && partner.consent === 0) {
        this.setLocalPartner(partner);
        return this.$router.push(`/my-partner/accept/${partner.id}`);
      } else if (partner.consent === 1) {
        return this.$modal.show({
          text: "You are about to view your partner's data instead of your own. Once you are viewing their data, please Tap the purple alert bar to go back to viewing your own data.",
          buttons: [
            {
              text: 'Ok',
              color: 'primary white--text',
              onAction: () => {
                if (partner.patientId) {
                  partner.viewPartner = true;

                  this.setLocalPartner(partner);

                  this.switchPatients(partner.patientId)
                    .then((res) => {
                      this.load();
                      if (this.viewPartner) {
                        this.setLocalPartner(null);
                        this.viewPartner = false;
                      } else {
                        this.viewPartner = true;
                      }

                      this.$router.push('/');
                      this.$store.dispatch(
                        'messages/pullNotificationUnreadCount',
                        true
                      );
                    })
                    .catch((err) => {
                      this.setLocalPartner(null);
                      this.viewPartner = false;
                    });
                }
              }
            }
          ]
        });
      }
    }
  }
};
</script>
