var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.partners && _vm.partners.length)?_c('div',_vm._l((_vm.partners),function(partner,index){return _c('v-row',{key:index,staticClass:"d-flex flex-row"},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-card',{class:`px-4
            py-4
            rounded
            primary--text
            d-flex
            align-content-space-between
            justify-space-between
            align-center
            ${hover ? 'elevation-1' : 'elevation-0'}`,staticStyle:{"transition":"all 0.2s ease-in-out","width":"100%","margin-top":"10px"},attrs:{"outlined":""}},[(partner)?_c('a',{staticClass:"text-decoration-none",on:{"click":function($event){return _vm.handlerPartner(partner)}}},[_c('div',{staticClass:"d-flex flex-column"},[_c('span',[_vm._v(" "+_vm._s(partner.name)+" - "+_vm._s(partner.relationship)+" ")]),(partner)?_c('span',{staticClass:"deep-orange--text text-sm-body-2"},[_vm._v(" "+_vm._s(_vm.getSubtitle(partner))+" ")]):_vm._e()])]):_vm._e(),(!_vm.viewPartner)?_c('v-icon',{staticClass:"red--text text--darken-4",attrs:{"size":"20"},on:{"click":function($event){return _vm.handlerRemovePartner(partner)}}},[_vm._v(" mdi-delete ")]):_vm._e()],1)]}}],null,true)})],1)}),1):_c('div',{staticClass:"text-center blue-grey--text text--lighten-4 font-weight-light"},[_c('p',[_vm._v("No Partner Linked")])]),_c('v-spacer'),(_vm.showInvitePartner)?_c('v-btn',{staticClass:"mt-8",attrs:{"color":"primary","rounded":"","block":"","large":"","to":"/my-partner/invitationType"}},[_vm._v(" Connect a Partner ")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }